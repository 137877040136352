import logo from './logo.png';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core'; 
import NavBar from './components/NavBar'
import Grid from './components/Grid'
import Footer from './components/Footer'
import './App.css';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
//import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
//changes to imports 
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
//import ComputerIcon from '@mui/icons-material/Computer';
//import HttpIcon from '@mui/icons-material/Http';


const theme = createMuiTheme({
  palette: {
    primary: {
      main:"#2e1667",
    },
    secondary: {
      main:"#c7d8ed",
    },
  },
  typography: {
    fontFamily: [
      'Ubuntu'
    ],
    h4: {
      fontWeight: 600,
      fontSize: 36,
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: 100,
      lineHeight: '2rem',
    },
  },
});

const styles = makeStyles({
  wrapper: {
    
    width: "65%",
    margin: "auto",
    textAlign: "center"
  },
  newsFeed: {
    
    width: "35%",
    margin: "auto",
    textAlign: "center",
    marginTop: "5rem"
  },
  bigSpace: {
    marginTop: "5rem"
  },
  littleSpace:{
    marginTop: "2.5rem",
  },
  grid:{
    display: "flex", 
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap", 
  },
})

function App() {
  const classes = styles();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <NavBar/>
        <div className={classes.wrapper}>
          
          
          
          <Typography variant="h4" className={classes.bigSpace} >
             Xandeum Founder's Club
          </Typography>
        </div>

        <router>

          <div className={`${classes.grid} ${classes.bigSpace}`}>

            <Grid icon={<StorageRoundedIcon style={{fill: "#238e7c", height:"125", width:"125"}}/>}  title="Bitoku Validator" btnTitle="Stake Now" 
            linkTo="https://www.validators.app/validators/BitokuDHQiAhpUKrwx1VssAAoW5Rst8zB6gpfoaxM3Kh?locale=en&network=mainnet" target="_blank" rel="noreferrer" />
       
            <Grid icon={<CurrencyExchangeOutlinedIcon style={{fill: "#238e7c", height:"125", width:"125"}}/>} title="Technical & Tokenomics" btnTitle="White Paper" 
            linkTo="https://xandeum.com/wp-content/uploads/2023/07/XandeumWhitepaper.pdf" target="_blank" rel="noreferrer" />

            <Grid icon={<KeyboardDoubleArrowUpOutlinedIcon style={{fill: "#238e7c", height:"125", width:"125"}}/>}  title="Getting Started" btnTitle="How-To" 
            linkTo="https://xandeum.com/" target="_blank" rel="noreferrer" />

          </div>

{/*       <div className={`${classes.grid} ${classes.littleSpace}`}>  
            <Grid icon={<ImportExportIcon style={{fill: "#5EA780", height:"125", width:"125"}}/>}  title="Discord Invite" btnTitle="Show me More"/>

            <Grid icon={<ComputerIcon style={{fill: "#E69426", height:"125", width:"125"}}/>}  title="Member Mint" btnTitle="Show me More"/>

            <Grid icon={<HttpIcon style={{fill: "#2EA09D", height:"125", width:"125"}}/>} title="Future" btnTitle="Show me More"/>

          </div> */}


        </router>

        <div className={classes.wrapper}>
          <Typography variant="h5" className={classes.bigSpace} >
            Xandeum: An L1 Smart Contract Platform with Scalable Blockchain-Based Storage.
          </Typography>
        </div>

        <div className={`${classes.grid} ${classes.bigSpace}`}>

        <Grid icon={<RedeemOutlinedIcon style={{fill: "#fba000", height:"125", width:"125"}}/>}  title="Member Mint" btnTitle="Mint"
        linkTo="https://mint.bitokufoundersclub.com/" target="_blank" rel="noreferrer"/>

        </div>


        {/* <div className={classes.newsFeed}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="bitokufounders"
          options={{height: 800}}
          theme="dark"
          transparent
          />
        
        </div> */}

        <div className="App-footer">
          <Footer/>
        </div>

      </ThemeProvider>
    </div>
  );
}

export default App;
