import React from 'react'
import CustomBtn from './CustomBtn'
import logo from '../logo.png'
import logo192 from '../logo192.png'
import {Toolbar, Typography} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles'

const styles = makeStyles({
    bar:{
        paddingTop: "1.15rem",
        paddingBottom: "1.15rem",
        background: "#000000",
        ['@media (max-width:780px)']: { 
           flexDirection: "column"
          }
    },
    logo: {
        width: "7%", 
        ['@media (max-width:780px)']: { 
           display: "none"
           }
    },
    logoMobile:{
        width: "100%", 
        display: "none", 
        ['@media (max-width:780px)']: { 
            display: "inline-block"
            }
    },
    menuItem: {
        cursor: "pointer", 
        flexGrow: 1,
        "&:hover": {
            color:  "#4f25c8"
        },
        ['@media (max-width:780px)']: { 
            paddingBottom: "1rem"    }
    }
})

export default function NavBar() {
    const classes = styles()
    return (
            <Toolbar position="sticky" className={classes.bar}>   
                <img src={logo} className="App-logo" alt="logo" />
                {/* <img src={logo192} className={classes.logoMobile} alt="logo" /> */}
                
                <Typography variant="h6" className={classes.menuItem} >
                    <a
                    className="App-link"
                    href="https://docs.xandeum.com" target="_blank" rel="noreferrer"
                    >
                    Xandeum Docs
                    </a>
                </Typography>
                             
               
                <CustomBtn txt="Request" href="https://get.xandeum.com/signup?grp=foundersclub" target="_blank" rel="noreferrer"/>
            </Toolbar>
    )
}

