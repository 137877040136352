import React from 'react'
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const styles = makeStyles({
    wrapper: {
       display: "flex",
       backgroundColor: "#222222",
       flexDirection: "row", 
       alignItems: "center", 
       padding: "0 1rem 0 1rem",
    }, 
    item: {
       paddingTop: "1rem"
    }
})

export default function Footer() {
    const classes = styles();
    return (
        <BottomNavigation className={classes.wrapper} >
          <BottomNavigationAction label="Facebook" value="recents" icon={<FacebookIcon  style={{fill: "#cccccc"}}/>} href="https://facebook.com/bitokufounders" target="_blank" rel="noreferrer"/>
          <BottomNavigationAction label="Twitter" value="favorites" icon={<TwitterIcon  style={{fill: "#cccccc"}}/>} href="https://twitter.com/bitokufounders" target="_blank" rel="noreferrer"/>
          <BottomNavigationAction label="Instagram" value="nearby" icon={<InstagramIcon  style={{fill: " #cccccc"}}/>} href="https://instagram.com/bitokufounders" target="_blank" rel="noreferrer"/>
          <BottomNavigationAction label="YouTube" value="folder" icon={<YouTubeIcon  style={{fill: "#cccccc"}}/>} href="https://youtube.com/channel/UCpEQRjtKpsuYHURwsgz3png" target="_blank" rel="noreferrer"/>
        </BottomNavigation>
)
}
